.MuiBadge-colorPrimary.pink {
  background-color: #f50050;
}

.CI {
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.CI-en {
  top: 36px;
  right: 30px;
}

.CI-he {
  top: 36px;
  left: 30px;
}

@media screen and (max-width: 940px) {
  .CI {
    width: 18px;
    height: 18px;
    background: #f50050;
    position: absolute;
    top: 12px;
    right: 10px;
    border-radius: 50%;
  }
}