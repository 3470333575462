.IPAB__actions--button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 193px;
  height: 56px;
  border-radius: 16px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 16px;
  cursor: auto;
}

.IPAB__items-amount {
  color: #15222f;
  max-width: 35px;
  border: none;
  text-align: center;
}

.IPAB-button {
  width: 193px;
  height: 56px;
  border-radius: 16px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 940px) {
  .IPAB__actions--button {
    width: 100%;
    max-width: 900px;
    margin: 26px auto;
  }

  .IPAB-button {
    width: 100%;
    margin-top: 26px;
  }
}