.form-input-autocomplete {
  background: none !important;
  background-color: white;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid rgba(21, 34, 47, 0.08) !important;
  opacity: 0.8;
  box-sizing: border-box;
  color: #000000CC;
  font-size: 16px;
  font-weight: normal;
}

.pac-container {
  z-index: 9999;
}

.search-location-input {
  display: relative;
}