.search-list-item {
    display: flex;
}

.search-list-item__img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 8px;
}

.search-list-item__description {
    display: flex;
    gap: 24px;
    flex: 3;
}

.search-list-item__price {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0.72;
}