.IPMAT__image-section {
  display: flex;
  align-items: center;
}

.IPMAT__merchant-img {
  max-width: 20px;
  max-height: 20px;
  padding: 6px 6px 6px 0;
}

.IPMAT__merchant-img img {
  width: 100%;
  height: 100%;
}

.IPMAT__supplier {
  opacity: 0.4;
  text-decoration: none;
}

.IPMAT__title {
  font-weight: bold;
}

@media screen and (max-width: 940px) {
  .IPMAT__title {
    margin-bottom: 24px;
    font-family: DM Serif Display;
    font-style: normal;
    font-size: 29px;
    line-height: 40px;
    color: #15222f;
  }
}
