.options-list {
    position: absolute;
    top: 60px;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    width: 100%;
    border-radius: 0px 0px 30px 30px;
    font-size: 24px;
    font-family: DM Serif Display;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;
    color: #15222f;
    border-radius: 0px 0px 30px 30px;
}

.options-list li {
    padding: 18px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 0px 0px 30px 30px;
}

.options-list li:hover {
    background-color: #FFFFFF;
}

.search__empty-state {
    display: flex;
    gap: 16px;
    background: #FFFFFF;
    opacity: 1;
}

input:focus {
    outline: none;
}

.show-full-list {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #80BB34;
}

.show-full-list:hover {
    border-radius: 0px 0px 30px 30px;
}