.sort-dropdown-container {
  z-index: 1;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.sort-dropdown {
  padding: 16px;
  width: 225px;
  border-radius: 16px;
  border: none;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.sort-dropdown-label {
  color: #15222F;
  opacity: 0.8;
}

.sort-dropdown-options-container {
  background: #ffffff;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 225px;
  border: none;
  border-radius: 16px;
  z-index: 2;
  height: auto;
  padding: 0 16px;
}

.sort-dropdown-option {
  padding: 16px 0;
  width: 225px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 10;
}

@media screen and (max-width: 940px) {
  .sort-dropdown-container {
    display: none;
  }
}