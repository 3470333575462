.header-responsive {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 16px;
  z-index: 100;
  max-width: 1680px;
  width: 95%;
  margin: 0px 16px;
}

.header-responsive__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
}

.header-responsive__menu-container {
  display: flex;
  align-items: center;
  width: 160px;
  gap: 15px;
}

.header-responsive__basket-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  cursor: pointer;
  padding-right: 10px;
}

.header-responsive__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(29, 38, 102, 0.08);
  border-radius: 30px;
  height: 40px;
  /* font-size: 23px; */
  background-image: url("./search.png");
  background-repeat: no-repeat;
  padding: 8px 12px 8px 38px;
  background-position: 15px;
  background-size: 16px;
  margin-top: 16px;
}

.header-responsive__search::placeholder {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #15222f;
  opacity: 0.4;
  position: static;
  width: 295px;
  left: calc(50% - 295px / 2 + 12px);
  top: 30%;
  bottom: 30%;
}

@media screen and (min-width: 940px) {
  .header-responsive {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .header-responsive {
    width: 90%;
  }
}
