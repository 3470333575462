.group {
  position: relative;
}

.form-input-label {
  color: grey;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
  white-space: nowrap;
}

.form-input-label.shrink {
  top: -14px;
  font-size: 12px;
  color: #000000CC;
}

.group input[type="password"] {
  letter-spacing: 0.3em;
}

.form-input {
  background: none;
  background-color: white;
  color: grey;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid grey;
  box-sizing: border-box;
}

.form-input:focus {
  outline: none;
}

.form-input:focus~.form-input-label {
  top: -14px;
  font-size: 12px;
  color: black;
}

.FI__error {
  color: red;
}

.error {
  color: red;
}

/* .valid {
  color: black;
} */

.form-input.error {
  border-bottom: 1px solid red;
}

.form-input-label.error {
  color: red;
}

/* .form-input.valid {
  border-bottom: 1px solid black;
}

.form-input-label.valid {
  color: black;
} */