@import url("https://fonts.googleapis.com/css2?family=Assistant&display=swap");

.header {
  /* position: fixed; */
  padding: 32px 40px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  max-width: 1600px;
  width: 100%;
  box-sizing: border-box;
  /* background-color: rgba(245,247,249,0.5); */
  /* backdrop-filter: blur(15px); */
}

.header-res {
  display: none;
}

.header__logo {
  /* max-height: 50px; */
  max-width: 50px;
  width: 100%;
  height: 100%;
}

.header-left-container {
  width: 82%;
  max-width: 1130px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.header__container-for-rest {
  display: flex;
  max-width: 1127px;
}

.header__search__select__container {
  display: flex;
  width: 58%;
  max-width: 58%;
}

.header__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(29, 38, 102, 0.08);
  border-radius: 30px;
  height: 56px;
  font-size: 23px;
  background-image: url("./search.png");
  background-repeat: no-repeat;
  padding: 0px 16px 0px 40px;
  background-position: 16px;
}

.header__search:focus {
  outline: none;
}

.header__categories-dropdown {
  height: 56px;
  width: 35%;
  max-width: 193px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  border: none;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  padding: 16px;
  box-sizing: border-box;
}

.header__categories-dropdown-title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: #15222F;
  opacity: 0.8;
}

.header__categories-dropdown-en {
  margin-left: 40px;
}

.header__categories-dropdown-he {
  margin-right: 40px;
}

.header__language {
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
}

.header__management {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 35%;
  max-width: 35%;
}

.header__delivery-address {
  display: flex;
  /* margin-left: 40px; */
  width: 320px;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}

.HM__delivery-address {
  display: flex;
  align-items: center;
}

.headerTruckIconHe {
  margin-right: 12px;
}

.headerTruckIconEn {
  margin-left: 12px;
}

@media screen and (max-width: 940px) {
  .header {
    display: none;
  }

  .header-res {
    display: flex;
    max-width: 900px;
  }
}

@media screen and (min-width: 940px) {
  .header-res {
    display: none;
  }
}

@media screen and (max-width: 940px) {
  .header__logo {
    max-height: 50px;
    max-width: 50px;
    width: 100%;
    height: 100%;
  }
}