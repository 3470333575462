.SPP__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 120px;
}

.SPP {
  margin-top: 112px;
  display: flex;
  flex-direction: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 56px 0px 48px 0px;
}

.SPP__title {
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 32px;
  color: #15222f;
  opacity: 0.64;
  margin-right: 48px;
}

.active {
  color: #15222f;
  opacity: 1 !important;
}

@media screen and (max-width: 940px) {
  .SPP__container {
    margin-bottom: 58px;
  }

  .SPP__title {
    font-size: 19px;
    line-height: 24px;
  }
}