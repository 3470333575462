@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500&family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');

.dm-serif {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f7f9;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
  border-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

/*
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
  */

#root {
  position: relative;
}

.h1 {
  font-family: DM Serif Display;
  font-style: normal;
  font-size: 73px;
  line-height: 100px;
  color: #15222f;
}

.h2 {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 80px;
  color: #15222f;
}

.h3 {
  font-family: DM Serif Display;
  font-style: normal;
  font-size: 29px;
  line-height: 40px;
  color: #15222f;
}

.h3-italic {
  font-family: DM Serif Display;
  font-style: italic;
  font-size: 29px;
  line-height: 40px;
}

.h4-medium {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  color: #15222f;

  opacity: 0.8;
}

.h4-mobile {
  font-family: DM Serif Display;
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  color: #15222f;
}

.h4-light {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 32px;
}

.h5-semi-bold {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
}

.h5-medium {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
}

.h5-regular {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-size: 19px;
  line-height: 24px;
}

.h5-light {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 24px;
}

.h5-regular-strikethrough {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 24px;
  text-decoration-line: line-through;
}

.h6-semi-bold {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
}

.h6-medium {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.h6-regular {
  /* h6 regular */
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  /* dark */

  color: #15222f;
  opacity: 0.64;
}

.h7-medium {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.h7-regular {
  font-family: Barlow, Assistant;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
}

.yellow {
  background: #f8be2a;
}

.dark {
  background: #15222f;
}

.green {
  background: #80bb34;
}

.dark-green {
  background: #43af75;
}

.white {
  background: #ffffff;
}

.blue {
  background: #1f74f5;
}

.red {
  background: #f50050;
}

/* dark 8% */
.dark-8 {
  background: rgba(21, 34, 47, 0.08);
}

.light-grey {
  background: #f5f7f9;
}

.shadow {
  background: #c4c4c4;
  box-shadow: 0px 16px 32px rgba(29, 38, 102, 0.08);
}

.d-block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.MuiAccordion-root {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.PhoneInputInput {
  /* max-width: 320px !important; */
  width: 100%;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  outline: none !important;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  padding-bottom: 8px;
}

img#engtLauncherIcon {
  width: 64px !important;
  height: 64px !important;
  max-height: 64px !important;
  max-width: 64px !important;
}

button:hover {
  opacity: 0.8;
}