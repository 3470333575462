.IPIG__image-container {
  max-width: 600px;
  width: 100%;
}

.IPIG__image-container--image {
  /* width: 543px; */
  height: 543px;
  margin-bottom: 40px;
}

.IPIG__image-container--img {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.IPIG__image-container__thumbnail {
  width: 120px;
  height: 120px;
}

.IPIG__image-container--image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.IPIG__image-container--image-gallery-img {
  width: 120px;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.IPIG__image-container--image-gallery-img.selected {
  outline: 2px solid #80BB34;
}

.IPIG__broken-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 100%;
  width: 100%;
  background: #fff;
  justify-content: center;
  border-radius: 8px;
}

@media screen and (min-width: 1100px) and (max-width: 1330px) {
  .IPIG__image-container--image {
    /* width: 450px; */
    height: 450px;
    max-width: 450px;
    max-height: 450px;
  }
}

@media screen and (min-width: 940px) and (max-width: 1100px) {
  .IPIG__image-container--image {
    /* width: 350px; */
    height: 350px;
    max-width: 350px;
    max-height: 350px;
  }
}

@media screen and (max-width: 940px) {
  .IPIG__image-container--image {
    width: auto;
    /* height: 900px;
    max-width: 900px;
    max-height: 900px; */
  }

  .IPIG__image-container {
    max-width: 100%;
    width: 100%;
  }

  button.slick-arrow.slick-prev {
    left: 0px;
    z-index: 1;

  }

  button.slick-arrow.slick-next {
    right: 0px;
  }
}