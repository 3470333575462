.item {
  display: flex;
  justify-content: space-between;
  padding: 56px 0 160px 0;
}

.item__details {
  width: 45%;
}

.item__details__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px dashed rgb(21, 33, 46, 0.08);
}

.item__details__info__weight {
  opacity: 0.4;
}

.item__details__info__actions {
  display: flex;
  align-items: center;
  direction: ltr;
}

.item__details__info__actions--price {
  height: 32px;
  width: 100px;
  margin-right: 16px;
}

span.item__details__info__actions--reaction {
  cursor: pointer;
}

.item-details__look-alike--item-name {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item__variants {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

.item__variants-title {
  color: #15222f;
  opacity: 0.8;
}

.item__variants-variant {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 16px;
  padding: 8px;
  cursor: pointer;
}

.item__variants-variant-img {
  height: 56px;
  width: 56px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 16px;
}

.item__variants-variant-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.item__variants-variant-info {
  display: flex;
  flex-direction: column;
}

.item__variants-variant-name {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #15222f;
  opacity: 0.8;
}

.item__variants-variant-price {
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: #15222f;
  opacity: 0.72;
}

.item__variants-variant.selected {
  outline: 2px solid #80bb34;
}

.item__details__info__actions-container {
  padding: 16px 0px 32px 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 940px) and (max-width: 1350px) {
  .item__details {
    width: 55%;
  }
}

@media screen and (max-width: 940px) {
  .item {
    display: none;
  }

  .item__variants-variant {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    border-radius: 16px;
    padding: 8px;
    width: 100%;
    cursor: pointer;
  }
}
